@import url("https://fonts.googleapis.com/css?family=Montserrat:400,900");

html {
  min-height: 100%;
}

body {
  height: 100vh;
  min-height: 100%;
  margin: 0;
  padding: 0;
  // overscroll-behavior-y: none;
  background-color: #0347e4;
  // overflow: hidden;
  display: flex;
  flex-direction: column;

  & * {
    font-family: "Montserrat", sans-serif;
    color: #ffffff;
  }
}

.hide {
  display: none !important;
}

.TfftCountdown {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  @media only screen and (min-width: 550px) {
    flex-direction: row;
  }


  .group {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @for $i from 0 through 100 {
      &.bg-#{$i} {
        &::before {
          content: "";
          position: absolute;
          background-color: rgba(255, 255, 255, 0.2);
          transition: all 300ms;
          @media only screen and (max-width: 550px) {
            right: #{100-$i} + "%";
            width: #{$i} + "%";
            height: 33.3%;
          }
          @media only screen and (min-width: 550px) {
            top: #{100-$i} + "%";
            height: #{$i} + "%";
            width: 33.3%;
          }
        }
      }
    }

    .digit {
      font-size: 2.5em;
      letter-spacing: 0.05em;
      line-height: 3rem;
      font-weight: 900;

      @media only screen and (min-width: 550px) {
        font-size: 2.7em;
        letter-spacing: 0.05em;
        line-height: 3rem;
      }

      @media only screen and (min-width: 920px) {
        font-size: 5em;
        letter-spacing: 0.05em;
        line-height: 6rem;
      }
    }

    .label {
      font-size: 1.8em;
      line-height: 1.5rem;
      font-weight: 400;
      text-transform: uppercase;

      @media only screen and (min-width: 550px) {
        font-size: 1.3em;
        letter-spacing: 0.05em;
        line-height: 1.1rem;
      }

      @media only screen and (min-width: 920px) {
        font-size: 2em;
        letter-spacing: 0.05em;
      }
    }

    // replace @each
    &:nth-child(1) {
      background-color: #014e92;
    }

    &:nth-child(2) {
      background-color: #035eae;
    }

    &:nth-child(3) {
      background-color: #186db8;
    }

    &:nth-child(4) {
      background-color: #2d76b6;
    }
  }
}
